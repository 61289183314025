import React from 'react'
import _ from 'lodash'
import { Dropdown } from '../../../../ui'
import {
  customStylesOnlyText,
  customStylesColours,
  customStylesCheckBox,
  customStylesSizes,
} from '../../../../ui/dropdown/custom-styles'

function DesktopFilters({
  availableFilters,
  brandsFiltered,
  colorMasterOptionsPlaceholder,
  categoryOptions,
  filterOptions,
  hideFilters,
  loadingBrands,
  loadingNextSizeMaster,
  loadingSkus,
  pageSizeOptions,
  resultColorMasterOptions,
  resultSizeMasterOptions,
  selectedOptionSortBy,
  sizeMasterOptionsPlaceholder,
  sortByOptionsPlaceholder,
  sortByOptions,
  skus,
  styles,
  t,
  handlePageSizeOptionChange,
  handleColorOptionChange,
  handleSizeOptionChange,
  handleBrandOptionChange,
  handleCategoryChange,
  handleSortByChange,
  onFetchSizeMasterNextPage,
}) {
  const fiters = (side) => (
    _.map(_.get(availableFilters, `desktopView.${side}`), (filter) => {
      let elem;
      switch (true) {
        case filter === 'color' && !hideFilters:
          elem = (
            <Dropdown
              key={`desktop_filter_${filter}`}
              className={styles.dropdownFeatures}
              placeholder={colorMasterOptionsPlaceholder}
              customStyles={customStylesColours}
              options={resultColorMasterOptions}
              dark
              isColors
              isMulti
              closeMenuOnSelect={false}
              onChange={handleColorOptionChange}
              values={_.get(filterOptions, 'colorMasterCodeEq')}
            />
          )
          break;
        case filter === 'size' && !hideFilters:
          elem = (
            <Dropdown
              key={`desktop_filter_${filter}`}
              className={styles.dropdownFeatures}
              placeholder={sizeMasterOptionsPlaceholder}
              customStyles={customStylesSizes}
              options={resultSizeMasterOptions}
              dark
              isMulti
              onChange={handleSizeOptionChange}
              onFetchNextPage={onFetchSizeMasterNextPage}
              loading={loadingNextSizeMaster}
              values={_.get(filterOptions, 'sizeMasterCodeEq')}
            />
          )
          break;
        case filter === 'brand' && !hideFilters:
          elem = !_.isEmpty(brandsFiltered) ? (
            <Dropdown
              key={`desktop_filter_${filter}`}
              className={styles.dropdownFeatures}
              placeholder={t('screens.products.brands')}
              customStyles={customStylesCheckBox}
              options={brandsFiltered}
              isMulti
              dark
              closeMenuOnSelect={false}
              isCheckBox
              onChange={handleBrandOptionChange}
              values={_.get(filterOptions, 'brandCodeEq')}
              disabled={_.isEmpty(brandsFiltered)}
              loading={loadingBrands}
            />
          ) : null
          break;
        case filter === 'category' && !hideFilters:
          elem = !_.isEmpty(categoryOptions) ? (
            <Dropdown
              key={`desktop_filter_${filter}`}
              className={styles.dropdownFeatures}
              placeholder={t('screens.products.categories')}
              customStyles={customStylesSizes}
              options={categoryOptions}
              dark
              onChange={handleCategoryChange}
              // values={_.get(filterOptions, 'categoryCodeEq')}
              disabled={_.isEmpty(categoryOptions)}
            />
          ) : null
          break;
        case filter === 'sortBy' && (!_.isEmpty(skus) || loadingSkus):
          elem = (
            <Dropdown
              key={`desktop_filter_${filter}`}
              className={styles.dropdownSort}
              placeholder={sortByOptionsPlaceholder}
              customStyles={customStylesOnlyText}
              options={sortByOptions}
              label={t('screens.products.sort')}
              onChange={handleSortByChange}
              values={selectedOptionSortBy}
              isSearchable={false}
            />
          )
          break;
        case filter === 'view' && (!_.isEmpty(skus) || loadingSkus):
          elem = (
            <Dropdown
              key={`desktop_filter_${filter}`}
              className={styles.dropdownView}
              placeholder={_.get(filterOptions, 'pageSize', '').toString()}
              customStyles={customStylesOnlyText}
              options={pageSizeOptions}
              label={t('screens.products.view')}
              onChange={handlePageSizeOptionChange}
              values={_.toNumber(_.get(filterOptions, 'pageSize'))}
              isSearchable={false}
            />
          )
          break;
        default:
          break;
      }
      return elem
    })
  )
  return (
    <div className={styles.filterBox}>
      <div className={styles.filterGroup}>
        {fiters('left')}
      </div>
      <div className={styles.filterGroupRight}>
        {fiters('right')}
      </div>
    </div>
  )
}

export default DesktopFilters
