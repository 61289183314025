import _ from 'lodash'
import URI from 'urijs'
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import {
  cancelRequest,
  useCategories,
} from 'react-omnitech-api'
import { useAlert } from '../../../../hook/use-alert'
import useSku from '../../../../hook/use-sku'
import useOrderMethod from '../../../../hook/use-order-method'
import { useLocation } from '../../../../hook/use-location'
import useCurrentOrderingPeriod from '../../../../hook/use-current-ordering-period'
import getTopLevelCategoryCode from '../../../../helpers/get-top-level-category-code'
import CategoryMenuView from './category-menu-view'

const CategoryMenuController = (props) => {
  const {
    categoryCodeEq,
    onCategoryChange = () => {},
  } = props
  const {
    fetchCategoriesAll,
    fetchCategoryByCode,
  } = useCategories()
  const currentSlotCode = useCurrentOrderingPeriod()
  const {
    getMetaMenuFilterParams,
    getMetaMenuCodeFilterParamsAsync,
  } = useSku()
  const alert = useAlert()
  const { orderMethod, store } = useOrderMethod()
  const { location } = useLocation()

  const menuFilterIntervalTimer = useRef(null)
  const menuCodeFilterIntervalTimer = useRef(null)
  const categoriesTimer = useRef(null)

  const [menuFilterDependency, setMenuFilterDependency] = useState('')
  const [menuCodeFilterDependency, setMenuCodeFilterDependency] = useState('')
  const [categories, setCategories] = useState([])
  const [parentCategory, setParentCategory] = useState({})
  const [loading, setLoading] = useState(true)

  const topCategoryCode = getTopLevelCategoryCode({
    topLevelCategory: _.get(orderMethod, 'topLevelCategory'),
    storeCode: _.get(store, 'code', ''),
  })

  const commerceChannelFromUrl = useMemo(() => {
    const url = new URI(_.get(location, 'href'))
    const search = url.search(true)
    return _.get(search, 'cc')
  }, [location])
  const commerceChannelFromOrderMethod = useMemo(() => (
    _.get(orderMethod, 'commerceChannel')
  ), [orderMethod])

  const orderingCategoriesSortingBySlotCode = useCallback((_categories) => (
    _.sortBy(_categories, (_categories, ({ meta }) => (
      _.includes(_.get(meta, 'firstPrioritySlotCode', []), currentSlotCode) ? 0 : 1
    )))
  ), [currentSlotCode])

  /**
   * fetchCategoriesApi
   * get product data from API
   */
  const fetchCategoriesApi = async () => {
    cancelRequest.cancelAll(['fetchCategories'])
    if (_.isEmpty(categoryCodeEq)) {
      setLoading(false)
      return
    }
    setLoading(true)
    try {
      // api call option
      const { category: currentCategory } = await fetchCategoryByCode({
        code: categoryCodeEq,
        includes: ['parent'].join(','),
      })
      const parent = topCategoryCode === _.get(currentCategory, 'parent.code')
        ? currentCategory
        : _.get(currentCategory, 'parent', currentCategory)
      setParentCategory(parent)
      const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const option = _.omitBy({
        includes: [
          'meta',
        ].join(','),
        parentCodeEq: _.get(parent, 'code'),
        skuActive: true,
        'skus|product_type_eq': 'product',
        ...menuFiltersParam,
        ...menuCodeFiltersParam,
        arrayFormat: 'brackets',
      }, _.isNil)
      // call api
      const { categories: data } = await fetchCategoriesAll(option)
      setCategories(orderingCategoriesSortingBySlotCode(data))
    } catch (error) {
      const generalError = _.get(error, 'generalError', {})
      alert.show(generalError.message)
    } finally {
      setLoading(false)
    }
  }

  const updateMenuFilterDependency = async () => {
    const menuFiltersParam = getMetaMenuFilterParams({ prefix: 'skus' })
    const dependency = _.join(
      _.sortedUniq(
        _.flatten(
          _.values(menuFiltersParam),
        ),
      ),
      ',',
    )
    setMenuFilterDependency(dependency)
  }

  const updateMenuCodeFilterDependency = async () => {
    try {
      const menuCodeFiltersParam = await getMetaMenuCodeFilterParamsAsync({ prefix: 'skus' })
      const dependency = _.join(
        _.sortedUniq(
          _.flatten(
            _.values(menuCodeFiltersParam),
          ),
        ),
        ',',
      )
      setMenuCodeFilterDependency(dependency)
    } catch (error) {
      // fail silently
    }
  }

  useEffect(() => {
    updateMenuFilterDependency()
    updateMenuCodeFilterDependency()
    menuFilterIntervalTimer.current = setInterval(() => {
      updateMenuFilterDependency()
    }, 1000 * 10) // update menu filter dependency every 10 seconds
    menuCodeFilterIntervalTimer.current = setInterval(() => {
      updateMenuCodeFilterDependency()
    }, 1000 * 60 * 10) // update menu code filter dependency every 10 minutes

    return () => {
      clearInterval(menuFilterIntervalTimer.current)
      clearInterval(menuCodeFilterIntervalTimer.current)
      cancelRequest.cancelAll(['fetchCategories', 'fetchCategoryByCode'])
    }
  }, [])

  useEffect(() => {
    // Wait for commerceChannel change
    if (_.isEqual(
      commerceChannelFromUrl,
      commerceChannelFromOrderMethod,
    ) || _.isEmpty(commerceChannelFromUrl)) {
      clearTimeout(categoriesTimer.current)
      setCategories([])
      categoriesTimer.current = setTimeout(fetchCategoriesApi, 500)
    }
  }, [
    menuFilterDependency,
    menuCodeFilterDependency,
    commerceChannelFromUrl,
    commerceChannelFromOrderMethod,
  ])

  useEffect(() => {
    if (
      !loading
      && !_.includes(
        _.map([...categories, parentCategory], 'code'),
        categoryCodeEq,
      )
    ) {
      setCategories([])
      fetchCategoriesApi()
    }
  }, [categoryCodeEq])

  const viewProps = {
    categories,
    loading,
    onCategoryChange,
    parentCategory,
    selectedCategory: categoryCodeEq,
  }
  return (
    <CategoryMenuView
      {...viewProps}
    />
  )
}

export default CategoryMenuController
